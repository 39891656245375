// Constants
const hamburger = document.querySelector('#hamburger');
const answers = document.querySelectorAll('.question-anwser--label');
const progressBar = document.querySelector('.progressbar-wrapper');

// Hamburger
function hamburgerMenuToggle(): void {
    hamburger?.addEventListener('click', () => {
        hamburger?.classList.toggle('open');
    });
}

// Fixed on scroll
function progressBarFixedOnScroll(): void {
    window.addEventListener('scroll', () => {
        if (progressBar && window.pageYOffset > progressBar.offsetTop) {
            progressBar?.classList.add('progressbar-fixed');
        } else {
            progressBar?.classList.remove('progressbar-fixed');
        }
    });
}

// Answer label change
function answerLabelChange(): void {
    answers.forEach((answer) => {
        answer.addEventListener('click', () => {
            answers.forEach((a) => {
                a.classList.remove('not-selected');
            });

            answers.forEach((el) => {
                if (el !== answer) {
                    el.classList.add('not-selected');
                }
            });
        });
    });
}

// Back to top button smooth scroll
function backToTopButton(): void {
    const backToTop = document.getElementById('btn-back-to-top');

    function scrollToTop(): void {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }
    backToTop?.addEventListener('click', scrollToTop);
}

document.addEventListener('DOMContentLoaded', () => {
    hamburgerMenuToggle();
    answerLabelChange();
    progressBarFixedOnScroll();
    backToTopButton();
});
